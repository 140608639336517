import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const PrivacyPolicy = ({ data, location }) => {
  const content = data.contentfulLegal.privacyPolicy

  return (
    <Layout location={location}>
      <Seo title="Privacy Policy" />
      <div className="section-wrapper page-main">
        <div className="content-column">
          <div className="text-section">{renderRichText(content)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query PrivacyContent {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLegal {
      privacyPolicy {
        raw
      }
    }
  }
`
